import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useContext, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "../context/ToastContext";
import ModalChangePassword from "../components/ModalChangePassword/ModalChangePassword";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const ForgotPassword = () => {
  const { sendResetPasswordEmail, verifyResetCode, userIdData } = useContext(AuthContext);
  const showToast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleResetPasswordClick = async () => {
    setLoading(true);
    try {
      setVerificationCode("");
      await sendResetPasswordEmail(email);
      showToast("success", "Verifique seu email para redefinir a senha.");
      setModalOpen(true);
    } catch (error) {
      showToast("error", "Falha ao enviar o email. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  // const handleVerifyCodeClick = async () => {
  //   setIsVerifying(true);
  //   try {
  //     const isValid = await verifyResetCode(email, verificationCode);
  //     if (isValid) {
  //       navigate("/auth/reset-password");
  //     } else {
  //       showToast("error", "Código de verificação inválido. Tente novamente mais tarde.");
  //     }
  //   } catch (error) {
  //     showToast("error", "Falha ao verificar o código. Tente novamente mais tarde.");
  //   } finally {
  //     setIsVerifying(false);
  //   }
  // };

  const handleVerifyCodeClick = async () => {
    setIsVerifying(true);
    try {
      if (await verifyResetCode(email, verificationCode)) {
        setChangePasswordModalOpen(true);
        setModalOpen(false);
      } else {
        showToast("error", "Código de verificação inválido. Tente novamente.");
      }
    } catch (error) {
      showToast("error", "Falha ao verificar o código.");
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <>
      <LoadingSpinner />
      <Col lg="5" md="7">
        <Card className="shadow-lg border-0 p-4" style={{ borderRadius: "20px", backgroundColor: "#f0f2f5" }}>
          <img
            style={{ width: "63%" }}
            alt="Logo"
            className="rounded-circle mx-auto d-block mt-3 mb-4"
            src={require("../assets/img/brand/logoAtt.png")}
          />
          <div className="px-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Esqueceu a senha? Coloque seu email para resetar.</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="email"
                    placeholder="Email"
                    className="input-group-alternative"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  onClick={handleResetPasswordClick}
                  color="secondary"
                  disabled={loading}
                  style={{ backgroundColor: "#6c757d", color: "#FFF", borderRadius: "12px" }}
                >
                  Enviar link de redefinição
                </Button>
              </div>
            </Form>
          </div>
        </Card>
        <Row className="mt-3">
          <Col xs="12" className="text-center">
            <a
              className="text-muted"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                navigate("/auth/login");
              }}
            >
              <small>Voltar ao Login</small>
            </a>
          </Col>
        </Row>
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Coloque o código de verificação</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                className="input-group-alternative"
                name="verificationCode"
                placeholder="Código de verificação"
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                style={{ borderRadius: "10px" }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={handleVerifyCodeClick}
              disabled={isVerifying}
              style={{ backgroundColor: "#6c757d", color: "#FFF", borderRadius: "12px" }}
            >
              Verificar código
            </Button>
            <Button color="secondary" onClick={() => setModalOpen(false)} style={{ borderRadius: "12px" }}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <ModalChangePassword
          openModal={changePasswordModalOpen}
          closeModal={() => setChangePasswordModalOpen(false)}
          userBasedId={userIdData}
        />
      </Col>
    </>
  );
};


export default ForgotPassword;