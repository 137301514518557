import { ReactNode, createContext } from "react";
import useTaxTasks, { TaxTasksType, UpdateTaxTasksType } from "./hooks/useTaxTasks";

interface TaxTasksContextProviderProps {
    children: ReactNode;
}

export interface TaxTasksContextData {
    getAllTaxTaskPaged: (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<TaxTasksType[] | null>;
    getAllTaxTaskPagedByCustomerId: (
        pageNumber: number,
        routineId?: number,
        customerId?: number,
        status?: number,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<TaxTasksType[] | null>;
    updateTaxTasks: (updateTaxTasksType: UpdateTaxTasksType) => Promise<UpdateTaxTasksType | null>;
    exportTaxTasks: (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<void | null>;
    exportTaxTasksByCustomerId: (
        pageNumber: number,
        routineId?: number,
        customerId?: number,
        status?: number,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<void | null>;
    getTaxTaskById: (taskId: number) => Promise<TaxTasksType | null>;
    setTaxTasks: any;
    TaxTasks: TaxTasksType;
    listTaxTasks: TaxTasksType[];
    initialListTaxTasks: TaxTasksType[];
    setListTaxTasks: any;
    setTaxTasksPageCount: any;
    taxTasksPageCount: number;
    setTaskPageCount: any;
    taskPageCount: number;
}

const initialState: TaxTasksContextData = {
    getAllTaxTaskPaged: async (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    getAllTaxTaskPagedByCustomerId: async (
        pageNumber: number,
        routineId?: number,
        customerId?: number,
        status?: number,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    updateTaxTasks: (updateTaxTasksType: UpdateTaxTasksType) => {
        return Promise.resolve(null);
    },
    exportTaxTasks: async (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    exportTaxTasksByCustomerId: async (
        pageNumber: number,
        routineId?: number,
        customerId?: number,
        status?: number,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    getTaxTaskById: async () => Promise.resolve(null),
    setTaxTasks: () => { },
    TaxTasks: {
        id: 0,
        observation: "",
        shippingDate: "",
        submissionDate: "",
        workerDownloadDate: "",
        status: "",
        hasDocument: false,
        routine: {
        //     id: 0,
            description: "",
        //     initialDate: "",
        //     tags: [],
            emails: [],
            // customer: {
        //         razaoSocial: "",
        //         cnpj: "",
                // emailCobranca: "",
            // },
        },
        description: "",
        razaoSocial: "",
        tags: [],
    },
    listTaxTasks: [],
    initialListTaxTasks: [],
    setListTaxTasks: () => {},
    setTaxTasksPageCount: () => {},
    taxTasksPageCount: 0,
    setTaskPageCount: () => {},
    taskPageCount: 0,
};

export const TaxTasksContext =
    createContext<TaxTasksContextData>(initialState);

export default function TaxTasksContextProvider({
    children,
}: TaxTasksContextProviderProps) {
    const {
        getAllTaxTaskPaged,
        getAllTaxTaskPagedByCustomerId,
        updateTaxTasks,
        exportTaxTasks,
        exportTaxTasksByCustomerId,
        getTaxTaskById,
        setTaxTasks,
        TaxTasks,
        listTaxTasks,
        initialListTaxTasks,
        setListTaxTasks,
        setTaxTasksPageCount,
        taxTasksPageCount,
        setTaskPageCount,
        taskPageCount,
    } = useTaxTasks();

    return (
        <TaxTasksContext.Provider
            value={{
                getAllTaxTaskPaged,
                getAllTaxTaskPagedByCustomerId,
                updateTaxTasks,
                exportTaxTasks,
                exportTaxTasksByCustomerId,
                getTaxTaskById,
                setTaxTasks,
                TaxTasks,
                listTaxTasks,
                initialListTaxTasks,
                setListTaxTasks,
                setTaxTasksPageCount,
                taxTasksPageCount,
                setTaskPageCount,
                taskPageCount,
            }}
        >
            {children}
        </TaxTasksContext.Provider>
    );
}