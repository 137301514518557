import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Table,
    Button,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    InputGroup,
    ModalFooter,
} from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import { AuthContext } from '../../context/AuthContext';
import { TaxTasksContext } from '../../context/TaxTasksContext';
import { TaxTasksType, UpdateTaxTasksType } from '../../context/hooks/useTaxTasks';
import './CustomerTaskTasks.css';
import { DownloadContext } from '../../context/DownloadContext';
import { FilesContext } from '../../context/FilesContext';
import { EmailContext } from '../../context/EmailContext';
import { UploadContext } from '../../context/UploadContext';
import Swal from 'sweetalert2';
import useUser from '../../context/hooks/useUser';
import { format, subMonths, startOfMonth, endOfMonth, parseISO } from 'date-fns';

const CustomerTaxTasks = () => {
    const { objUserTk } = useContext(AuthContext);
    const {
        listTaxTasks,
        setListTaxTasks,
        getAllTaxTaskPagedByCustomerId,
        updateTaxTasks,
        exportTaxTasksByCustomerId,
        getTaxTaskById,
        taxTasksPageCount,
        setTaxTasksPageCount,
    } = useContext(TaxTasksContext);
    const { handleUpload } = useContext(UploadContext);
    const { handleDownloadByTaxTasksId } = useContext(DownloadContext);
    const { getFileName, fileName } = useContext(FilesContext);
    const { sendNotificationEmail } = useContext(EmailContext);
    const { getPermissionsByUser, userPermissions } = useUser();
    const [loading, setLoading] = useState(false);
    const [dataSearch, setDataSearch] = useState({
        routineDescription: '',
        startDate: '',
        endDate: '',
        status: 0,
        tag: '',
    });
    const defaultStartDate = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');
    const defaultEndDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [selectedTaskId, setSelectedTaskId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingTaxTasks, setEditingTaxTasks] = useState<UpdateTaxTasksType | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedTaxTasksId, setSelectedTaxTasksId] = useState(0);
    const statusOptions = [
        { value: 0, label: 'Todos' },
        { value: 1, label: 'Em Aberto' },
        { value: 2, label: 'Concluído' },
        { value: 3, label: 'Atrasado' },
        { value: 4, label: 'Rejeitado' },
        { value: 5, label: 'Aprovado' }
    ];

    useEffect(() => {
        setListTaxTasks([]);
        setTaxTasksPageCount(0);
        getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId);
    }, []);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (objUserTk?.UserId) {
                const permissions = await getPermissionsByUser(objUserTk.UserId);
            }
        };

        fetchPermissions().catch(error => console.error("Erro ao carregar permissões:", error));
    }, [objUserTk?.UserId]);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllTaxTaskPagedByCustomerId(value, 0, objUserTk.UserId);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    };

    const handleSearchSubmit = () => {
        setLoading(true);
        getAllTaxTaskPagedByCustomerId(
            1,
            0,
            objUserTk.UserId,
            dataSearch.status,
            dataSearch.routineDescription,
            dataSearch.startDate,
            dataSearch.endDate,
            dataSearch.tag
        )
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleClearFilters = () => {
        setDataSearch({
            routineDescription: '',
            startDate: '',
            endDate: '',
            status: 0,
            tag: '',
        });
        setLoading(true);
        getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleDownloadFile = async (officeId: number, taxTasksId: number) => {
        await handleDownloadByTaxTasksId(officeId, taxTasksId);
    };

    const formatDateTime = (dateString: string) => {
        if (!dateString) return 'Data não informada';
        const date = parseISO(dateString);
        return dateString.includes('T')
            ? format(date, 'dd/MM/yyyy HH:mm')
            : format(date, 'dd/MM/yyyy');
    };

    const formatTaskStatus = (status: number) => {
        switch (status) {
            case 1:
                return { label: 'Em Aberto', color: '#007bff' };
            case 2:
                return { label: 'Concluído', color: '#28a745' };
            case 3:
                return { label: 'Atrasado', color: '#ffc107' };
            case 4:
                return { label: 'Rejeitado', color: '#dc3545' };
            case 5:
                return { label: 'Aprovado', color: '#ffcc00' }
            default:
                return { label: 'Desconhecido', color: '#6c757d' };
        }
    };

    const handleEditTaxTasks = (taxTasks: TaxTasksType) => {
        const updateData: UpdateTaxTasksType = {
            id: taxTasks.id,
            observation: taxTasks.observation,
        };
        setEditingTaxTasks(updateData);
        setEditModalOpen(true);
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingTaxTasks) {
            updateTaxTasks(editingTaxTasks)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        handleCloseEditModal();
                        getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao atualizar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar tarefa:", error);
                });
        }
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        Swal.fire({
            title: 'Deseja enviar um e-mail para notificar o cliente?',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            icon: 'question'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (!editingTaxTasks || !editingTaxTasks.id) {
                    Swal.fire("Erro", "Nenhuma tarefa válida foi selecionada para notificação.", "error");
                    return;
                }

                try {
                    const taskDetails = await getTaxTaskById(editingTaxTasks.id);

                    if (!taskDetails || !taskDetails.routine) {
                        Swal.fire("Erro", "Não foi possível obter os detalhes da tarefa ou rotina.", "error");
                        return;
                    }

                    const routineEmails = taskDetails.routine.emails;
                    if (!routineEmails || routineEmails.length === 0) {
                        Swal.fire("Aviso", "A rotina não possui e-mails configurados.", "warning");
                        return;
                    }

                    const nomeTarefa = taskDetails.routine.description || "Tarefa sem descrição";

                    const emailData = {
                        from: "info@b7inovacao.com.br",
                        to: routineEmails,
                        subject: "Atualização da tarefa",
                        text: `Informamos que a tarefa "${nomeTarefa}" foi atualizada.`,
                        html: `<p>Informamos que a tarefa <strong>${nomeTarefa}</strong> foi atualizada.</p>`,
                        templateId: "",
                    };

                    const success = await sendNotificationEmail(emailData);

                    if (success) {
                        Swal.fire("Sucesso", "E-mail enviado aos destinatários com sucesso.", "success");
                    } else {
                        Swal.fire("Erro", "Não foi possível enviar o e-mail aos destinatários.", "error");
                    }
                } catch (error) {
                    Swal.fire("Erro", "Ocorreu um erro ao buscar os detalhes ou enviar o e-mail.", "error");
                    console.error("Erro ao processar a notificação por e-mail:", error);
                }
            }
        });
    };

    const handleExport = () => {
        exportTaxTasksByCustomerId(
            1,
            0,
            objUserTk.UserId,
            dataSearch.status,
            dataSearch.routineDescription,
            dataSearch.startDate,
            dataSearch.endDate,
            dataSearch.tag
        );
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Tarefas"}
                search={false}
                titleSearch={""}
            />

            <Container style={{ paddingLeft: 0, paddingRight: 0 }} className="mt-5">
                <Row>
                    <Col xs="12">
                        <Form className="d-flex">
                            <Col md="2">
                                <FormGroup>
                                    <Label for="routineDescription" className='mb-0'>Rotina</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="routineDescription"
                                        id="routineDescription"
                                        placeholder="Descrição da rotina"
                                        value={dataSearch.routineDescription}
                                        onChange={handleSearchChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="status" className='mb-0'>Status</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="select"
                                        name="status"
                                        id="status"
                                        value={dataSearch.status}
                                        onChange={handleSearchChange}
                                    >
                                        {statusOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="startDate" className='mb-0'>Data Inicial</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="date"
                                        name="startDate"
                                        id="startDate"
                                        placeholder="Data inicial"
                                        value={dataSearch.startDate}
                                        onChange={handleSearchChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="endDate" className='mb-0'>Data Final</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        placeholder="Data final"
                                        value={dataSearch.endDate}
                                        onChange={handleSearchChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="tag" className='mb-0'>Tag</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="tag"
                                        id="tag"
                                        placeholder="Buscar por tag"
                                        value={dataSearch.tag}
                                        onChange={handleSearchChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Form>
                    </Col>

                    <Col xs="12" className="d-flex justify-content-end mt--4">
                        <Button className="search-btn me-2" onClick={handleSearchSubmit}>Buscar</Button>
                        <Button className="clear-btn me-2 btn-danger" onClick={handleClearFilters}>Limpar</Button>
                        <Button className="export-btn" onClick={handleExport}>Exportar</Button>
                    </Col>
                </Row>

                {userPermissions.includes(2) ? (
                    <Row className="mt-6">
                        <Col>
                            <Table hover responsive className="table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th>Rotina</th>
                                        <th>Data de cobrança</th>
                                        <th>Enviar em</th>
                                        {/* <th>Dt. Robô</th> */}
                                        <th>Status</th>
                                        <th>Tags</th>
                                        <th>Download</th>
                                        <th>Upload</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listTaxTasks.map((taxTasks: TaxTasksType, index: number) => {
                                        const formattedStatus = formatTaskStatus(Number(taxTasks.status));
                                        return (
                                            <tr key={index}>
                                                <td>{taxTasks.description.length > 20 ? taxTasks.description.substring(0, 20) + '...' : taxTasks.description}</td>
                                                <td>{formatDateTime(taxTasks.shippingDate)}</td>
                                                <td>{taxTasks.submissionDate ? formatDateTime(taxTasks.submissionDate) : 'Não enviada'}</td>
                                                {/* <td>{taxTasks.workerDownloadDate ? formatDateTime(taxTasks.workerDownloadDate) : 'Em espera'}</td> */}
                                                <td>
                                                    <span
                                                        className="status-capsule"
                                                        style={{ backgroundColor: formattedStatus.color }}
                                                    >
                                                        {formattedStatus.label}
                                                    </span>
                                                </td>
                                                <td>
                                                    {taxTasks.tags.map((tag, index) => (
                                                        <span key={index} className="tag">{tag}</span>
                                                    ))}
                                                </td>
                                                <td>
                                                    {taxTasks.hasDocument || Number(taxTasks.status) !== 2 ? (
                                                        <Button
                                                            className="action-btn"
                                                            onClick={() => handleDownloadFile(objUserTk.OfficeId, taxTasks.id)}
                                                            disabled={!taxTasks.hasDocument}
                                                        >
                                                            <i className="fa-solid fa-download"></i> Baixar
                                                        </Button>
                                                    ) : (
                                                        <span className="no-document">Sem documento</span>
                                                    )}
                                                </td>
                                                <td>
                                                    <Button className="action-btn" onClick={() => {
                                                        setSelectedTaskId(taxTasks.id);
                                                        setIsFileUploadModalOpen(true);
                                                    }}>
                                                        <i className="fa-solid fa-upload"></i> Upload
                                                    </Button>
                                                    <Button
                                                        className="action-btn"
                                                        onClick={async () => {
                                                            if (taxTasks.id) {
                                                                const uploadResponse = await handleUpload({
                                                                    taxTaskId: Number(taxTasks.id),
                                                                    officeId: objUserTk.OfficeId,
                                                                    fileName: "",
                                                                    files: [],
                                                                });
                                                                if (uploadResponse === null) {
                                                                    // await getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId, dataSearch.status, dataSearch.routineDescription, defaultStartDate, defaultEndDate, dataSearch.tag)
                                                                    await getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId, dataSearch.status, dataSearch.routineDescription, dataSearch.startDate, dataSearch.endDate, dataSearch.tag)
                                                                }
                                                            } else {
                                                                alert("Selecione uma tarefa.");
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-upload"></i> Nada a declarar
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-3">
                                {taxTasksPageCount && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <PaginationMaterial
                                            onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                            count={taxTasksPageCount}
                                        />
                                    </div>
                                )}
                                {listTaxTasks.length === 0 && (
                                    <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-4">
                        <Col>
                            <div style={{ color: "red", textAlign: "center" }}>
                                Você não tem permissão para visualizar as tarefas.
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>

            <Modal isOpen={isFileUploadModalOpen} toggle={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)} className="modal-lg">
                <ModalHeader toggle={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)}>
                    Upload de Arquivo
                </ModalHeader>
                <ModalBody>
                    <MultipleFileUpload onFilesSelected={setFiles} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#585858", color: "#FFF" }}
                        onClick={async () => {
                            if (files.length > 0 && selectedTaskId) {
                                const uploadResponse = await handleUpload({
                                    taxTaskId: selectedTaskId,
                                    officeId: objUserTk.OfficeId,
                                    fileName: files.map(file => file.name).join(", "),
                                    files: files
                                });

                                if (uploadResponse === null) {
                                    setIsFileUploadModalOpen(false);
                                    await getAllTaxTaskPagedByCustomerId(1, 0, objUserTk.UserId);
                                }
                            } else {
                                alert("Selecione arquivos para upload.");
                            }
                        }}
                        disabled={files.length === 0}
                    >
                        Enviar Arquivos
                    </Button>
                    <Button color="secondary" onClick={() => setIsFileUploadModalOpen(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CustomerTaxTasks;