import React, { ReactNode, createContext, Dispatch, SetStateAction } from "react";
import useAuth, { FormPassword, Office, UserProps, UserToken } from "./hooks/useAuth";

interface AuthContextProviderProps {
  children: ReactNode;
}

type UserTypeCheck = 1 | 2 | 3;

const initialState: AuthContextData = {
  user: { password: "", email: "" },
  setUser: () => {},
  authenticated: false,
  officeRegister: async () => {},
  handleLogin: () => {},
  handleLogout: () => {},
  loadingButton: false,
  objUserTk: { unique_name: "", UserId: 0, UserName: "", Type: "Office", OfficeId: 0 },
  selectTypeUser: 1,
  setSelectTypeUser: () => {},
  formPassword: { newPassword: "", confirmPassword: "" },
  setFormPassword: () => {},
  handleChangePassword: async () => {},
  sendResetPasswordEmail: async () => 0,
  verifyResetCode: async () => false,
  userIdData: 0,
};

export interface AuthContextData {
  user: { password: string; email: string };
  setUser: (user: UserProps) => void;
  authenticated: boolean;
  officeRegister: (officeCreateRequest: Office) => void;
  handleLogin: (user: UserProps) => void;
  handleLogout: () => void;
  loadingButton: boolean;
  objUserTk: UserToken;
  selectTypeUser: UserTypeCheck;
  setSelectTypeUser: Dispatch<SetStateAction<UserTypeCheck>>;
  formPassword: FormPassword;
  setFormPassword: (data: FormPassword) => void;
  handleChangePassword: (newPassword: string, userId?: number) => Promise<void>;
  sendResetPasswordEmail: (email: string) => Promise<number | null>;
  verifyResetCode: (email: string, code: string) => Promise<boolean>;
  userIdData: number;
}

export const AuthContext = createContext<AuthContextData>(initialState);

export default function AuthContextProvider({ children }: AuthContextProviderProps) {
  const {
    data,
    authenticated,
    loadingButton,
    officeRegister,
    handleLogin,
    handleLogout,
    handleSetUser,
    objUserTk,
    selectTypeUser,
    setSelectTypeUser,
    formPassword,
    setFormPassword,
    handleChangePassword,
    sendResetPasswordEmail,
    verifyResetCode,
    userIdData,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        user: data,
        setUser: handleSetUser,
        authenticated,
        officeRegister,
        handleLogin,
        handleLogout,
        loadingButton,
        objUserTk: objUserTk,
        selectTypeUser,
        setSelectTypeUser,
        formPassword,
        setFormPassword,
        handleChangePassword,
        sendResetPasswordEmail,
        verifyResetCode,
        userIdData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
