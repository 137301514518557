import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface UserType {
    id: number;
    name: string;
    email: string;
    password?: string;
    office: {
        id: number;
        name: string;
        email: string;
        cnpj: string;
        area: string;
        photo: string;
        smtpHost: string;
        smtpPort: number;
        enableSsl: boolean;
        smtpUserName: string;
        smtpPassword: string;
        templateTarefasAntesDoVencimento: string;
        templateTarefasEmDia: string;
        templateTarefasAtrasadas: string;
    }
    userType: {
        id: number;
        type: string;
    }
}

export interface CreateUserType {
    name: string;
    email: string;
    password: string;
    userTypeId: number;
    officeId: number;
}

export interface UpdateUserType {
    id: number;
    name: string;
    email: string;
    password?: string;
    userTypeId?: number;
    office: {
        id: number;
        name?: string;
        email?: string;
        cnpj: string;
        area: string;
        photo: string;
        smtpHost: string;
        smtpPort: number;
        enableSsl: boolean;
        smtpUserName: string;
        smtpPassword: string;
        templateTarefasAntesDoVencimento: string;
        templateTarefasEmDia: string;
        templateTarefasAtrasadas: string;
    }
}

export default function useUser() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext)
    const [user, setUser] = useState<UserType>({
        id: 0,
        name: "",
        email: "",
        office: {
            id: 0,
            name: "",
            email: "",
            cnpj: "",
            area: "",
            photo: "",
            smtpHost: "",
            smtpPort: 0,
            enableSsl: false,
            smtpUserName: "",
            smtpPassword: "",
            templateTarefasAntesDoVencimento: "",
            templateTarefasEmDia: "",
            templateTarefasAtrasadas: "",
        },
        userType: {
            id: 0,
            type: "",
        },
    });
    const [listUsers, setListUsers] = useState<UserType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListUsers, setInitialListUsers] = useState<UserType[]>([]);
    const [userPermissions, setUserPermissions] = useState<number[]>([]);

    function getAllUserPaged(
        pageNumber: number,
        officeId?: number,
        name?: string,
    ): Promise<UserType[] | null> {
        setLoading(true);
        let url = `/Users/getAllUsersPaged?pageNumber=${pageNumber}&pageSize=9`;

        if (officeId) {
            url += `&officeId=${officeId}&routineId=0`;
        }

        if (name) {
            url += `&name=${name}`;
        }

        return api
            .get(url)
            .then((response) => {
                setListUsers(response.data.result);
                setInitialListUsers(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar tipo de usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar tipo de usuário:", error);
                return null;
            });
    }

    function createUser(createUserType: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        return api
            .post(`/Users/create`, createUserType)
            .then((response) => {
                const createUserType: CreateUserType = response.data;
                showToast("success", "Usuário criada com sucesso.");
                setLoading(false);
                return createUserType;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao criar usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao criar usuário:", error);
                return null;
            });
    }

    async function createUsersFromCsv(file: File): Promise<void> {
        setLoading(true);
        const formData = new FormData();

        formData.append('file', file);

        try {
            const response = await api.post(`/Candidate/create-from-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setLoading(false);
            showToast("sucess", "Candidatos criados com sucesso!");
        } catch (error) {
            setLoading(false);
            console.error("Erro ao criar candidatos a partir do CSV:", error);
            showToast("error", "Erro ao criar candidatos a partir do CSV.");
        }
    }

    function updateUser(userData: UpdateUserType): Promise<UpdateUserType | null> {
        setLoading(true);
        return api
            .put(`/Users/${userData.id}`, userData)
            .then((response) => {
                showToast("success", "Usuário atualizado com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar usuário:", error);
                return null;
            });
    }

    function deleteUser(userId: number): Promise<UserType | null> {
        setLoading(true);
        return api
            .delete(`/Users/${userId}`)
            .then(() => {
                setUser({
                    id: 0,
                    name: "",
                    email: "",
                    office: {
                        id: 0,
                        name: "",
                        email: "",
                        cnpj: "",
                        area: "",
                        photo: "",
                        smtpHost: "",
                        smtpPort: 0,
                        enableSsl: false,
                        smtpUserName: "",
                        smtpPassword: "",
                        templateTarefasAntesDoVencimento: "",
                        templateTarefasEmDia: "",
                        templateTarefasAtrasadas: "",
                    },
                    userType: {
                        id: 0,
                        type: "",
                    },
                });
                showToast("success", "Usuário deletado com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar usuário:", error);
                return null;
            });
    }

    function handleGetByUserId(userId: number): Promise<UserType | null> {
        setLoading(true);
        return api
            .get(`/Users/getbyuserid/${userId}`)
            .then((response) => {
                const user: UserType = response.data;
                setUser(user);
                setLoading(false);
                return user;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function handleGetByOfficeId(officeId: number): Promise<UserType | null> {
        setLoading(true);
        return api
            .get(`/Users/getbyofficeid/${officeId}`)
            .then((response) => {
                const user: UserType = response.data;
                setUser(user);
                setLoading(false);
                return user;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function handleGetByOfficeIdAndUserId(officeId: number, userId: number): Promise<UserType | null> {
        setLoading(true);
        return api
            .get(`/Users/getbyofficeidanduserid/${officeId}/${userId}`)
            .then((response) => {
                const user: UserType = response.data;
                setUser(user);
                setLoading(false);
                return user;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function checkFirstAccess(userId: number): Promise<boolean> {
        setLoading(true);
        return api
            .get(`/Users/isFirstAccess/${userId}`)
            .then((response) => {
                setLoading(false);
                return response.data.isFirstAccess;
            })
            .catch((error) => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor login novamente.", error);
                } else {
                    showToast("error", "Erro ao verificar o primeiro acesso. Tente novamente.", error);
                }
                return false;
            });
    }

    function updatePassword(userId: number, newPassword: string): Promise<boolean> {
        setLoading(true);
        return api
            .put(`/Users/updatePasswordAndFirstAccess/${userId}/${newPassword}`)
            .then(() => {
                showToast("success", "Senha atualizada com sucesso.");
                setLoading(false);
                return true;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor login novamente.", error);
                } else {
                    showToast("error", "Erro ao atualizar a senha. Verifique novamente mais tarde.", error);
                }
                setLoading(false);
                return false;
            });
    }

    function getPermissionsByUser(userId: number): Promise<number[]> {
        // setLoading(true);
        return api
            .get(`/Users/${userId}/permissions`)
            .then((response) => {
                setUserPermissions(response.data);
                // setLoading(false);
                return response.data;
            })
            .catch((error) => {
                // setLoading(false);
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor login novamente.", error);
                } else {
                    showToast(
                        "error",
                        "Erro ao obter permissões do usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                return [];
            });
    }

    return {
        createUser,
        getAllUserPaged,
        updateUser,
        deleteUser,
        listUsers,
        setListUsers,
        pageCount,
        setPageCount,
        initialListUsers,
        setInitialListUsers,
        user,
        setUser,
        handleGetByUserId,
        createUsersFromCsv,
        handleGetByOfficeId,
        handleGetByOfficeIdAndUserId,
        checkFirstAccess,
        updatePassword,
        getPermissionsByUser,
        userPermissions
    };
}